module.exports = function(gantt){

require("../core/relations/links_common")(gantt);

var _private = require("./critical_path/slack")(gantt);
_private.init();

gantt.getFreeSlack = function(task) {
	return _private.getFreeSlack(task);
};

gantt.getTotalSlack = function(task) {
	return _private.getTotalSlack(task);
};

var criticalPath = require("./critical_path/critical_path")(gantt);
gantt.config.highlight_critical_path = false;
criticalPath.init();

gantt.isCriticalTask = function (task) {
	gantt.assert(!!(task && task.id !== undefined), "Invalid argument for gantt.isCriticalTask");
	return criticalPath.isCriticalTask(task);
};

gantt.isCriticalLink = function (link) {
	return this.isCriticalTask(gantt.getTask(link.source));
};


gantt.getSlack = function(task1, task2) {
	var minSlack = 0;
	var relations = [];
	var common = {};

	for (var i = 0; i < task1.$source.length; i++) {
		common[task1.$source[i]] = true;
	}
	for (var i = 0; i < task2.$target.length; i++) {
		if(common[task2.$target[i]])
			relations.push(task2.$target[i]);
	}

	for (var i = 0; i < relations.length; i++) {
		var link = this.getLink(relations[i]);
		var newSlack = this._getSlack(task1, task2, this._convertToFinishToStartLink(link.id, link, task1, task2, task1.parent, task2.parent));

		if (minSlack > newSlack || i === 0) {
			minSlack = newSlack;
		}
	}

	return minSlack;
};

gantt._getSlack = function (task, next_task, relation) {
	// relation - link expressed as finish-to-start (gantt._convertToFinishToStartLink)
	var types = this.config.types;

	var from = null;
	if (this.getTaskType(task.type) == types.milestone) {
		from = task.start_date;
	} else {
		from = task.end_date;
	}

	var to = next_task.start_date;

	var duration = 0;
	if (+from > +to) {
		duration = -this.calculateDuration({start_date: to, end_date: from, task: task});
	} else {
		duration = this.calculateDuration({start_date: from, end_date: to, task: task});
	}

	var lag = relation.lag;
	if (lag && lag*1 == lag) {
		duration -= lag;
	}

	return duration;
};


};