import * as helpers from "../../utils/helpers";
import { ConstraintTypes } from "./constraint_types";
import { TaskPlan } from "./task_plan";

export class ConstraintsHelper {
	static Create(gantt: any): ConstraintsHelper {
		return new ConstraintsHelper(gantt);
	}

	private _gantt: any;
	private constructor(gantt: any) {
		this._gantt = gantt;
	}

	isAsapTask = (task: ITask): boolean => {
		const constraintType = this.getConstraintType(task);
		if (this._gantt.config.schedule_from_end) {
			if (constraintType === ConstraintTypes.ASAP) {
				return true;
			} else {
				return false;
			}
		} else {
			if (constraintType === ConstraintTypes.ALAP) {
				return false;
			} else {
				return true;
			}
		}
	}

	isAlapTask = (task: ITask): boolean => {
		return !this.isAsapTask(task);
	}

	getConstraintType = (task: ITask): ConstraintTypes => {
		// in case of backward scheduling, tasks without explicit constraints are considered ALAP tasks
		if (task.constraint_type) {
			return task.constraint_type;
		} else if (this._gantt.config.schedule_from_end) {
			return ConstraintTypes.ALAP;
		} else {
			return ConstraintTypes.ASAP;
		}
	}

	hasConstraint = (task: ITask): boolean => {
		return !!this.getConstraintType(task);
	}

	processConstraint = (task: ITask, plan: TaskPlan): TaskPlan => {
		if (this.hasConstraint(task)) {
			if (
				task.constraint_type === ConstraintTypes.ALAP ||
				task.constraint_type === ConstraintTypes.ASAP
			) {
				// this kind of constraint is calculated after main scheduling
			} else if(helpers.isValidDate(task.constraint_date)) {
				const constraintDate = task.constraint_date;

				const newPlan = TaskPlan.Create(plan);
				newPlan.task = task.id;

				switch (task.constraint_type) {
					case ConstraintTypes.SNET:
						newPlan.earliestStart = new Date(constraintDate);
						newPlan.earliestEnd = this._gantt.calculateEndDate({
							start_date: newPlan.earliestStart,
							duration: task.duration,
							task
						});
						newPlan.link = null;
						break;
					case ConstraintTypes.SNLT:
						newPlan.latestStart = new Date(constraintDate);
						newPlan.latestEnd = this._gantt.calculateEndDate({
							start_date: newPlan.latestStart,
							duration: task.duration,
							task
						});
						newPlan.link = null;
						break;
					case ConstraintTypes.FNET:
						newPlan.earliestStart = this._gantt.calculateEndDate({
							start_date: constraintDate,
							duration: -task.duration,
							task
						});
						newPlan.earliestEnd = new Date(constraintDate);
						newPlan.link = null;
						break;
					case ConstraintTypes.FNLT:
						newPlan.latestStart = this._gantt.calculateEndDate({
							start_date: constraintDate,
							duration: -task.duration,
							task
						});
						newPlan.latestEnd = new Date(constraintDate);
						newPlan.link = null;
						break;
					case ConstraintTypes.MSO:
						newPlan.earliestStart = new Date(constraintDate);
						newPlan.earliestEnd = this._gantt.calculateEndDate({
							start_date: newPlan.earliestStart,
							duration: task.duration,
							task
						});
						newPlan.latestStart = newPlan.earliestStart;
						newPlan.latestEnd = newPlan.earliestEnd;
						newPlan.link = null;
						break;
					case ConstraintTypes.MFO:
						newPlan.earliestStart = this._gantt.calculateEndDate({
							start_date: constraintDate,
							duration: -task.duration,
							task
						});
						newPlan.earliestEnd = this._gantt.calculateEndDate({
							start_date: newPlan.earliestStart,
							duration: task.duration,
							task
						});
						newPlan.latestStart = newPlan.earliestStart;
						newPlan.latestEnd = newPlan.earliestEnd;
						newPlan.link = null;
						break;
				}

				return newPlan;
			}
		}

		return plan;
	}

	getConstraints = (id: TaskID, relations: IInternalLink[]): ITask[] => {
		const result = [];
		const tasks = {};

		const store = (task: any) => {
			if (tasks[task.id]) {
				return;
			}

			if (this.hasConstraint(task) && !this._gantt.isSummaryTask(task)) {
				tasks[task.id] = task;
			}
		};

		if (this._gantt.isTaskExists(id)) {
			const task = this._gantt.getTask(id);
			store(task);
		}

		this._gantt.eachTask(task => store(task), id);

		let current;
		if (relations) {
			for (let i = 0; i < relations.length; i++) {
				const rel = relations[i];
				if (!tasks[rel.target]) {
					current = this._gantt.getTask(rel.target);
					store(current);
				}
				if (!tasks[rel.source]) {
					current = this._gantt.getTask(rel.source);
					store(current);
				}
			}
		}

		for (const taskId in tasks) {
			result.push(tasks[taskId]);
		}

		return result;
	}
}
